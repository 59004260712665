import React from "react";

import styles from './basic.module.less';

import Layout from "../components/layout";

export default function Template({
  location,
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data; // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark;
  return (
    <Layout location={location}>
      <div className="page-container">
        <h1 className={styles.title}>{frontmatter.title}</h1>
        <div
          className={styles.body}
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </div>
    </Layout>
  );
}

export const pageQuery = graphql`
  query PageByPath($path: String!) {
    markdownRemark(fields: { slug: { eq: $path } }) {
      html
      frontmatter {
        title
      }
    }
  }
`;
